import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const baseUrl = `http://ns3.redirect.one-isp.net/api`

const initialState = {
    site: null,
    customerStatus: null,
    secrets: null,
    packages: []
}

export const getSiteData = createAsyncThunk(
    'userStorage/getSiteData',
    async ({domain, siteIP, loginLink}) => {
        let siteData = await fetch(`${baseUrl}/site/${domain}/${siteIP}`).then((res) => res.json());
        let site = {
            BusinessID: siteData.BusinessID,
            BusinessName: siteData.BusinessName,
            Phone: siteData.Phone,
            Email: siteData.Email,
            Address: siteData.Address,
            TillNumber: siteData.TillNumber,
            PayBill: siteData.PayBill,
            siteIP,
            domain,
            loginLink
        }
        return {
            site,
            customer: siteData.Customer,
            packages: siteData.Packages.filter(f => f.Enabled && f.type === 'hotspot')
        }
    }
)


export const postPurchaseBundles = createAsyncThunk(
    'userStorage/purchaseBundles',
    async ({domain, data}) => {

        let options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
        };

        let resp = await fetch(`${baseUrl}/site/${domain}/hotspot-top-up`, options);

        if (resp.ok)
            return {success: true}
        else {
            let error = await resp.json();
            return {success: false, error}
        }
    }
)

export const postRedeemBundles = createAsyncThunk(
    'userStorage/postRedeemBundles',
    async ({domain, data}) => {

        let options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
        };

        let resp = await fetch(`${baseUrl}/site/${domain}/hotspot-redeem`, options);

        if (resp.ok)
            return {success: true}
        else {
            let error = await resp.json();
            return {success: false, error}
        }
    }
)

export const checkUserStatus = createAsyncThunk(
    'userStorage/checkUserStatus',
    async ({domain, mac}) => {
        let opt = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({Extra: {mac}})
        }
        let customerStatus = await fetch(`${baseUrl}/site/${domain}/check-hotspot-top-up`, opt).then((res) => res.json());
        return customerStatus
    }
)

export const addSecrets = createAsyncThunk(
    'userStorage/addSecrets',
    (secrets) => {
        return {
            secrets
        }
    }
)


const siteStorage = createSlice({
    name: 'userStorage',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSiteData.fulfilled, (state, action) => {
            state.site = action.payload.site
            state.customer = action.payload.customer
            state.packages = action.payload.packages
        })
        builder.addCase(checkUserStatus.fulfilled, (state, action) => {
            state.customerStatus = action.payload
        })
        builder.addCase(addSecrets.fulfilled, (state, action) => {
            state.secrets = action.payload.secrets
        })
    }
})

export default siteStorage.reducer
